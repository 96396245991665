<template>
  <div class="text-center leading-tight font-helvNeu">
    <p class="whitespace-nowrap mt-2 lg:mt-3 uppercase text-2xl lg:text-3xl">
      {{ trans('LOGO:Slogan1.1') }} <span class="text-redAccentLight">{{ trans('LOGO:Slogan1.2') }}</span> {{ trans('LOGO:Slogan1.3') }}
    </p>
    <p class="whitespace-nowrap mt-5 lg:mt-8 text-2xl lg:text-3xl ">
      {{ trans('LOGO:Slogan2.1') }} <span class="text-redAccentLight">{{ trans('LOGO:Slogan2.2') }}</span> {{ trans('LOGO:Slogan2.3') }}
    </p>
  </div>
</template>

<script>
export default {
  name: "HomepageSlogan"
}
</script>